const Footer = () => {
	return (
		<footer className='g-padding-x-30--xs g-padding-y-30--xs g-padding-x-60--md g-bg-color--white'>
			<div className='container'>
				<div className='row g-display-flex--md' style={{ alignItems: "center" }}>
					<div className='col-xs-12 col-sm-4 col-md-4'>
						<p className='g-font-size-14--xs g-text-center--xs g-text-left--md g-padding-y-10--xs g-margin-b-0--xs g-color--white-opacity'>
							<a href='/'>
								<img className='s-header-v2__logo-img s-header-v2__logo-img' src='/logo.png' alt='logo' height='40' />
							</a>
						</p>
					</div>
					<div className='col-xs-12 col-sm-4'>
						<p className='g-font-size-16--xs g-text-center--xs g-padding-y-10--xs g-margin-b-0--xs g-color--dark'>© 2023 GoBananas. All rights reserved.</p>
					</div>
					<div className='col-xs-12 col-sm-4'>
						<p className='g-font-size-16--xs g-text-right--md g-text-center--xs g-padding-y-10--xs g-margin-b-0--xs g-color--dark'>
							<a className='g-color--dark g-font-size-16--xs g-font-weight--400' href='mailto:support@gobananas.live' target='_blank' rel='noreferrer'>
								support@gobananas.live
							</a>
						</p>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;

const Header = () => {
	return (
		<div>
			<header className='navbar-fixed-top s-header js__header-sticky js__header-overlay'>
				<nav className='s-header-v2__navbar'>
					<div className='container g-display-table--lg' style={{ padding: 0 }}>
						<div className='s-header-v2__navbar-row'>
							<div className='s-header-v2__navbar-col'>
								<button
									type='button'
									className='collapsed s-header-v2__toggle g-margin-r-10--xs'
									data-toggle='collapse'
									data-target='#nav-collapse'
									aria-expanded='false'>
									<span className='s-header-v2__toggle-icon-bar'></span>
								</button>
							</div>

							<div className='s-header-v2__navbar-col s-header-v2__navbar-col-width--130'>
								<div className='s-header-v2__logo'>
									<a href='/' className='s-header-v2__logo-link'>
										<img className='s-header-v2__logo-img s-header-v2__logo-img--default' src='/logo.png' alt='logo' height='45' />
										<img className='s-header-v2__logo-img s-header-v2__logo-img--shrink' src='/logo.png' alt='logo' height='40' />
									</a>
								</div>
							</div>

							<div className='s-header-v2__navbar-col s-header-v2__navbar-col--right' style={{ marginRight: 0 }}>
								<div className='collapse navbar-collapse s-header-v2__navbar-collapse' id='nav-collapse'>
									<ul className='s-header-v2__nav'>
										<li className='s-header-v2__nav-item'>
											<a href='https://app.gobananas.live/' className='s-header-v2__nav-link'>
												<span className='g-bg-color--white g-color--dark g-padding-y-5--xs g-padding-x-0--xs g-radius--50'>Login</span>
											</a>
										</li>
										<li className='s-header-v2__nav-item'>
											<a href='#joinTheWaitlist' className='s-header-v2__nav-link'>
												<span className='g-bg-color--primary g-color--dark g-padding-y-5--xs g-padding-x-15--xs g-radius--50'>Join the waitlist</span>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</nav>
			</header>
		</div>
	);
};
export default Header;

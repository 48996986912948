import { Alert, AlertColor, Snackbar } from "@mui/material";
import { useState } from "react";
import InfoCard from "../components/infoCard";
import { addToWaitlist } from "../services/WaitlistService";

const LandingPage = () => {
	const [wlEmailId, setWlEmailId] = useState("");
	const [wlRefferalLink, setSlRefferalLink] = useState("");
	const [wlAlert, setWlAlert] = useState<{ severity: AlertColor; message: string; color: any }>({
		severity: "info",
		message: "",
		color: "primary",
	});
	const [wlAlertOpen, setWlAlertOpen] = useState(false);

	const validateEmail = (email: string) => {
		return email.match(
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
	};
	const submitToWaitlist = () => {
		if (validateEmail(wlEmailId)) {
			addToWaitlist(wlEmailId)
				.then((refferalLink) => {
					setWlAlert({
						severity: "success",
						message: "You have joined the waitlist",
						color: "primary",
					});
					return setSlRefferalLink(refferalLink);
				})
				.catch((err) => {
					setWlAlert({
						severity: "error",
						message: err.message,
						color: "error",
					});
				})
				.then(() => setWlAlertOpen(true));
		} else {
			setWlAlert({
				severity: "error",
				message: "Invalid Email address",
				color: "error",
			});
			setWlAlertOpen(true);
		}
	};

	const handleClose = () => {
		setWlAlertOpen(false);
	};

	return (
		<div className='landingPage'>
			<div className='container'>
				<div className='row'>
					<div className='col-md-7 g-fullheight--md'>
						<div id='joinTheWaitlist' className='g-margin-t-40--xs g-margin-t-120--md g-padding-y-100--xs'>
							<h1 className='g-font-size-60--lg g-font-size-60--md g-font-size-50--xs g-letter-spacing--2 animated floatInUp floating10'>
								<b>Streamline your Content Workflow</b>
							</h1>
							<p className='g-font-size-22--xs g-letter-spacing--1 g-line-height--sm g-margin-t-30--xs g-margin-t-20--md g-margin-b-40--xs  animated floatInUp floating20'>
								One Tool for Project Management, Collaboration, Asset Management, Feedback, Releases and Everything
							</p>
							<h4 className='g-color--primary g-font-weight--700  animated floatInUp'>Join the Waitlist!</h4>
							<div className='input-group g-width-450--md g-width-350--xs g-margin-b-0--xs  animated floatInUp'>
								<input
									type='search'
									value={wlEmailId}
									onChange={(event) => {
										setWlEmailId(event.target.value);
									}}
									className='form-control s-form-v2__input g-radius--left-50 g-radius--right-0 g-hor-border-0--right'
									placeholder='Enter your email'
								/>
								<span className='input-group-btn'>
									<button
										onClick={() => submitToWaitlist()}
										className='s-btn s-btn--md s-btn--primary-bg g-radius--right-50 g-font-size-10--xs g-radius--left-0 g-hor-border-0--left g-padding-x-25--xs'>
										<div className='material-icons-outlined'>arrow_forward</div>
									</button>
								</span>
							</div>
						</div>
					</div>
					<div className='col-xs-12 col-md-5'>
						<div className='landingPageGirl g-padding-y-60--xs g-padding-y-60--md g-margin-t-50--md g-margin-t-0--xs animated floatInUp'>
							<img className='g-width-400--lg g-width-350--md g-width-350--xs' src={"/img/landingPageGirl.jpg"} alt='landingPageGirl' />
						</div>
					</div>
				</div>
			</div>

			<div className='infoCardBg'>
				<div className='container g-padding-y-120--xs'>
					<h2 className='g-font-size-30--xs g-font-size-40--md g-text-center--xs g-color--white g-font-weight--700'>
						Allow Your Team and Content to Go Bananas
					</h2>
					<div className='row infoCardWrapper'>
						<div className='col-md-4'>
							<InfoCard
								icon={"flash_on"}
								heading={"One tool for everything"}
								content={
									"Bring your video content teams together in one centralized platform. Share files, ideas, and feedback seamlessly, enhancing collaboration and productivity. Move with urgency and focus to turn your ideas into reality."
								}
							/>
						</div>
						<div className='col-md-4'>
							<InfoCard
								icon={"shuffle_on"}
								heading={"Simplify Asset Sharing"}
								content={
									"Easily upload and share video files, scripts, storyboards, thumbnails, music and other files with your team. Say goodbye to scattered files and version control headaches. Trust in our reliable platform to safeguard your valuable content"
								}
							/>
						</div>
						<div className='col-md-4'>
							<InfoCard
								icon={"play_circle_filled"}
								heading={"Seamless Video Releases"}
								content={
									"Publish and distribute your videos from one place. Maintain control over permissions and access rights, ensuring your content is released efficiently and securely. We believe in fostering optimism and celebrating your successes."
								}
							/>
						</div>
					</div>
				</div>
			</div>
			<Snackbar open={wlAlertOpen} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity={wlAlert.severity} color={wlAlert.color} sx={{ width: "100%" }}>
					{wlAlert.message}
				</Alert>
			</Snackbar>
		</div>
	);
};

export default LandingPage;

const InfoCard = (props: any) => {
	return (
		<div className='infoCard g-text-center--xs g-radius--10 g-padding-y-15--xs g-padding-x-15--xs g-hor-border-1__solid--white'>
			<span className='g-font-size-36--xs g-margin-t-20--xs material-icons-outlined g-color--white'>{props.icon}</span>
			<p className='g-font-size-20--xs g-color--white g-font-weight--700 g-margin-t-20--xs'>{props.heading}</p>
			<p className='g-font-size-15--xs g-color--white'>{props.content}</p>
		</div>
	);
};

export default InfoCard;
